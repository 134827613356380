


import SECTION_VIPBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_VIPCONTENTSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_VIPCONTENTMSECTION from
'../dynamic_section/dynamic_2/template_01';






import VIPSection from '../sections/vip/default';






import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const VipPage = ()=>{
  
return (
  <div id="vipPage" class="page ">
  
  < SECTION_VIPBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `vip_title` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />

  
  < SECTION_VIPBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `vip banner` } id={ `vip_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  < SECTION_VIPBANNERSECTION desktopToggle={ false }
    mobileToggle={ false } label={ `vip banner` } id={ `vip_banner_m` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  

  

  
  
  
  

  
  <VIPSection desktopToggle={ true } mobileToggle={
    true } bgSection={ false } mobileTitleToggle={true} mobileBannerToggle={ false } desktopBannerToggle={
    false } mode={``}  vipContentToggle={true} joinNowContentToggle={false}  aboutUsToggle={ false }
    faqToggle={ false }tncToggle={ false } benefitToggle={
    false } reviewToggle={ false } />
  
  
  
  

  
  {/* < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } /> */}
  
  
  

  
</div>
)
}

export default VipPage;